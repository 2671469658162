import { createContext } from 'react';

export type SessionData = {
  countryCode: string;
  userEmail: string;
  googleMapsApiKey: string;
};

export type SessionContextData = {
  data: SessionData;
  loginUrl: string;
  isAuthenticated: boolean;
  removeAuthCookies: () => void;
};

export const SessionContext = createContext<SessionContextData>({
  data: {
    countryCode: '',
    userEmail: '',
    googleMapsApiKey: '',
  },
  loginUrl: '',
  isAuthenticated: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeAuthCookies: () => {},
});
